


















































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import path from 'path';
import api from '../util/api';
// @ts-ignore
import { Parallax, InfoAreas, ProfileCard, FullBgCard } from '@/client/components/_theme';
import Contact from '@/client/components/Contact.vue';
import CitySection from '@/client/components/CitySection.vue';
import Testimonials from '@/client/components/Testimonials.vue';
import GetInTouch from  '@/client/components/GetInTouch.vue';
import PassionsSectionHome from '@/client/components/PassionsSectionV2.vue';
import VideoHeader from '@/client/components/core/VideoHeader.vue';
import { ImageStrings } from '@/server/types';
import * as Constants from '@/client/components/constants';

@Component({
  components: {
    Parallax,
    InfoAreas,
    ProfileCard,
    FullBgCard,
    Contact,
    CitySection,
    Testimonials,
    GetInTouch,
    PassionsSectionHome,
    VideoHeader,
  },
})
export default class Landing extends Vue {
  Constants = Constants;
  headerImage: string = require('@/client/assets/images/office-5.jpg');

  fish: ImageStrings = {
    image1: require('@/client/assets/images/office-1.jpg'),
    image2: require('@/client/assets/images/office-2.jpg'),
    image3: require('@/client/assets/images/office-3.jpg'),
    image4: require('@/client/assets/images/office-4.jpg'),
  };
}
