




















import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { PricingCard } from '@/client/components/_theme';

@Component({
  components: {
    PricingCard,
  },
})
export default class Pricing extends Vue {
  @Prop({ required: true })
  readonly priceValues!: Array<any>
}
