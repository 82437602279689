













/* eslint-disable global-require */
import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { FullBgCard } from '@/client/components/_theme';

@Component({
  components: {
    FullBgCard,
  },
})
export default class Portfolio extends Vue {
  portfolioItems = [
    {
      title: 'Solar Supply Unlimited',
      image: require('@/client/assets/images/solar-supply-direct.jpg'),
    },
    {
      title: 'Insponetwork',
      image: require('@/client/assets/images/portfolio-insponetwork.jpg'),
    },
    {
      title: 'Rare Aquatic Finds',
      image: require('@/client/assets/images/rare-aquatic-finds.jpg'),
    },
    {
      title: 'Dragon Ball Super',
      image: require('@/client/assets/images/portfolio-dbs.jpg'),
    },
    {
      title: 'Garage Repair Unlimited',
      image: require('@/client/assets/images/garage-repair-unlimited.jpg'),
    },
    {
      title: 'MTG Counter',
      image: require('@/client/assets/images/always-fun-games.jpg'),
    },
    {
      title: 'Murfie',
      image: require('@/client/assets/images/portfolio-murfie.jpg'),
    },
    {
      title: 'Move Your Bump',
      image: require('@/client/assets/images/portfolio-mha-new.jpg'),
    },
    {
      title: 'Speed Racer',
      image: require('@/client/assets/images/portfolio-speed-racer.jpg'),
    },
  ];
}
