export const loremBlockLarge = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vehicula ut eros ac bibendum.
  Integer in orci at neque suscipit pellentesque tempor eu magna. Aenean eleifend erat et augue
  mollis, ac hendrerit eros porttitor. Vivamus sit amet est posuere, euismod nibh condimentum erat.
`;

export const loremBlockSmall = `
  Quisque at diam felis. Donec auctor eros arcu. Nunc et elit elit. Nullam eu ullamcorper mauris.
  Suspendisse egestas lacus quis volutpat cursus.
`;

export const loremSentence = 'Curabitur ligula leo, placerat at tincidunt eget, rhoncus vitae lacus.';

export const loremTitle = 'Lorem Ipsum';

export const aboutContent = {
  header: {
    title: 'About Optosearch',
    subTitle: `
      We are hard at work driving the marketing efforts to capture leads for all types of businesses and services.
      Let us save you the time and effort in marketing by supplying exactly the leads that are needed in order for
      your business to thrive
    `,
    text: `
      Let's face it, your online presence means everything in modern times. Marketing is the driving force of most
      businesses. Without being seen by the public, even the greatest companies and products could experience failure
      before success because of the modern requirements of having an effective marketing strategy.
    `,
  },
  passionSections: [
    {
      title: loremTitle,
      passions: [
        {
          title: loremTitle,
          text: loremBlockLarge,
          cardText: loremTitle,
          buttonText: 'Learn More',
          url: '/specialties/shrimp',
        },
        {
          title: 'Aquascapes',
          text: `
            Countless hours go into the planning and preparation for for any scape. You have to make sure that
            all proper nutrients and substrate are used, and that the right balance of plants and rocks are selected
            for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
            in it's own ways.
          `,
          cardText: 'Aquascapes',
          buttonText: 'Learn More',
          url: '/specialties/aquascapes',
        },
        {
          title: 'Discus Breeding',
          text: `
            As most enthusiasts understand, raising Discus cichlids are not easy!
            They require very specific and steady water conditions to survive and
            thrive. We take this very seriously! In our fish room, we have a Reverse
            Osmosis (R/O) filtration system set up to ensure only the most pristine
            water conditions, then we carefully add back the required minerals so
            that our Discus can thrive.
          `,
          cardText: 'Specialty Discus',
          buttonText: 'Learn More',
          url: '/specialties/discus',
        },
      ],
      fullImageBgSection: {
        title: 'We take pride in everything that we do',
        text: `
          So much so that if you are not satisfied with your purchase we will give you a full refund, no questions asked.
        `,
      },
    },
  ],
} as const;

export const spec1Content = {
  header: {
    title: 'Digital Marketing Services',
    subTitle: 'The Lifeblood for Most Businesses',
    text: `
      Imagine if leads were as easy as logging in and making phone calls. Well, they are! Our LeadGen & Copy Software collects
      and delivers high-quality leads based on your company's needs. Our software uses a carefully constructed algorithm to
      match you with leads that are almost guaranteed to convert. If you don't believe us, then try a one week free trial
      for no cost to you at all. For additional details, continue reading in the following sections to learn more.
    `,
  },
  passionSections: [
    {
      title: 'The Generation Services We Can Provide to Your Company',
      passions: [
        {
          title: 'Pay Per Lead',
          text: `
            If all you need to thrive is relevant leads, we have you covered. Let us know your unique goals and we will provide
            you with high-quality leads that are easy to convert. Once you have some relevant leads entering your business’
            pipeline, then you will have the opportunity to convert them into a loyal customer that will keep coming back over
            and over again. This entire process starts with our simple pay-per-lead plan to get you started on the right track.
          `,
          cardText: 'Pay Per Lead',
          buttonText: 'Get Started',
          routerConfig: {
            type: 'lead generation',
            subtype: 'pay per lead',
          },
        },
        {
          title: 'Lead Capture Pages',
          text: `
            If you prefer a custom solution for your needs, then you might be interested in our service that provides you with a
            custom lead generation website. This plan allows you to acquire a custom lead generation website for your specific
            needs. This is the primary way that you can set your leads on autopilot. By taking advantage of this feature, you will
            have the opportunity to generate a custom website with all of your lead generation features in mind.
          `,
          cardText: 'Lead Capture Page',
          buttonText: 'Lead Capture',
          routerConfig: {
            subtype: 'lead capture',
          },
        },
        {
          title: 'Lead Portal (Coming Soon)',
          text: `
            The Lead Portal is an all in one solution that allows you to fine-tune the leads that you receive. This gives your business
            full control of the process. This feature will be particularly useful for customization purposes. The portal will allow you
            to access several configuration settings to ensure that you get everything that you need to access in order to manage the
            lead generation process.
          `,
          cardText: 'Lead Portal',
          buttonText: 'Lead Portal',
          routerConfig: {
            subtype: 'portal',
          },
        },
      ],
      fullImageBgSection: {
        title: 'Highly Targeted, Relevant Leads Is What We Offer Your Business',
        text: `
          Over the years, we have fine-tuned our lead generation process. We know what people are looking for and how to collect
          the right information. All of our leads will be 100% relevant to your company, allowing you to focus on converting leads
          instead of generating them. By creating a pathway for leads to enter your pipeline, you are already setting yourself up
          for success. Half of the work is already done once the leads enter the pipeline. Your job will be simply to convert them
          and keep them loyal to your business for many years to come. We guarantee you’ll be thrilled with the quality of the leads
          you gain. If not, you’ll receive your money back. The satisfaction and success of our clients is our top priority.
        `,
      },
    },
  ],
} as const;

export const spec2Content = {
  header: {
    title: 'Maximize Conversions With Market Driven Design',
    subTitle: 'Our Team of Experts Know How to Drive Conversions',
    text: `
      We have helped our clients to achieve maximum online visibility through our extensive knowledge of marketing for almost any vertical.
      It is our mission to prioritize the success of your business and we will work with you every step of the way to ensure your new design
      will be everything you envisioned and have all the key elements to drive conversions. On average, our clients see a 20% increase in
      conversions on the first month alone.
    `,
  },
  passionSections: [
    {
      title: 'Our Website Design Services Include',
      passions: [
        {
          title: 'Basic Website',
          text: `
            All you need is something simple to showcase what you do or what you have to offer. You don't have the time to worry about
            designing your own website and content. With our Basic Website package, we design and develop a modern looking site to get
            your business off the ground. We handle a few of the professional looking assets so your site doesn't look stock, and set up a
            a simple lead capture form and email campaign. Our basic website package starts at $249 and includes one year of free hosting.
          `,
          cardText: 'Basic Website',
          buttonText: 'Get Started',
          routerConfig: {
            subtype: 'basic web',
          },
        },
        {
          title: 'Startup Website',
          text: `
            You have an amazing idea or product in the works and you need a site to maximize your online presence. This website needs to
            be one of a kind in terms of design and have a good user flow to ensure high conversion rates. Let us manage the design and
            development aspects of your online presence so that you can focus on what is most important, your business. Our startup package
            starts at $499 and includes one year of free hosting.
          `,
          cardText: 'Startup Package',
          buttonText: 'Get Started',
          routerConfig: {
            subtype: 'e commerce',
          },
        },
        {
          title: 'Professional Website',
          text: `
            You need the works, a custom website from the ground up that is capable of handling heavy traffic and ready to take orders. Our
            team will work directly with you to bring your vision to life. Up to 8 fully custom pages and 5 custom forms for data collection,
            email campaigns and more. We are sure that our design will leave a lasting impression on anyone that lands our your site. This package
            includes a personal project manager to answer any questios you may have and to ensure that the project stays on course with your vision.
            This package starts at $999 and includes 2 years of free hosting.
          `,
          cardText: 'Professional Website',
          buttonText: 'Get Started',
          routerConfig: {
            subtype: 'commercial grade',
          },
        },
      ],
    },
  ],
} as const;

export const spec3Content = {
  header: {
    title: 'Business Consultations From Our Senior Executives',
    subTitle: 'How we’ll help your business grow',
    text: `
      With over 10 years of industry experience, we know exactly what it takes to start and grow a business that thrives. Our proven
      consultation services are designed to help businesses plan and execute website applications and marketing strategies that
      increase overall profits and decrease overhead costs. We ensure our clients’ success by providing them with a detailed blueprint
      customized to their unique marketing needs and goals.
    `,
  },
  passionSections: [
    {
      title: 'How We Can Help You Succeed With Our Consultations',
      passions: [
        {
          title: 'Application Audit - Making Your Site a Lead Magnet',
          text: `
            At Optosearch, we’ve mastered the formula behind high-converting websites. We will take time to thoroughly analyze every
            detail of your existing site to determine any and all areas that have room for improvement. From there, we will work
            collaboratively with you and come up with a comprehensive plan to facilitate changes that will drive real results. If
            websites aren’t your thing, no worries - our team is equipped to make these updates for you and will work tirelessly
            to accommodate your timeline. There are numerous technical and design factors that could prevent your website from being
            successful. Trying to figure out which one it is without the help of a marketing expert is like finding the needle in a
            haystack, and the time wasted and sales missed will end up costing you more money than if you had hired someone to help
            from the start. We’ll provide the marketing insight you need to improve your website, raise conversion, and beat the competition.
          `,
          cardText: 'Standard Website Consultation For Your Company',
          buttonText: 'Website Consultation',
          routerConfig: {
            subtype: 'app audit',
          },
        },
        {
          title: 'Standard Marketing Consultation',
          text: `
            We understand how overwhelming it is to keep up with social media platforms and search engines, each with its own set of
            ever-changing trends and algorithms. If used correctly, digital marketing will become one of your highest-converting sales
            strategies; if used inadequately, it can lead to wasting your company’s time and money. We’re here to ensure your results
            are the former and to protect you from the latter. Whether you’re just looking to refine your technique or you’re starting
            from square one, our team will build a customized marketing plan specifically designed to empower your team to reach its
            goals and maximize ROI. We’ll identify your target audience’s preferred marketing channels and help you develop strategies
            to reach them. Then, we’ll create a system to measure the performance of your marketing campaigns that works for you.
          `,
          cardText: 'Marketing Plan Audit',
          buttonText: 'Consulation',
          routerConfig: {
            subtype: 'business audit',
          },
        },
        {
          title: 'Consultations With an Integrated Approach',
          text: ` 
            Businesses today face a myriad of challenges: Struggling to be innovative, constantly adapting to industry standards, appealing
            to customers, and much more. Whether you’re facing a specific issue or just need help bridging a few gaps, we’re here to help!
            Our consulting services will identify any potential threats to your strategy and provide you with the necessary tools to address
            them. From evaluating the effectiveness of your most recent marketing campaign to conducting an assessment of your online presence,
            we’ll analyze your business’s KPIs to the very last detail in order to bring the fresh perspective you need to progress. Our team
            of experts will work as an extension of your team to assess and improve your company’s sales, customer insights, online presence,
            strategy execution, and anything else you need. In order to get ahead you’ll need a map to see where you’re currently at, and a
            guide to help find your way forward - that’s where we come in.
          `,
          cardText: 'Comprehensive Business Consultation, Leaving Nothing Out',
          buttonText: 'Comprehensive',
          routerConfig: {
            subtype: 'comprehensive audit',
          },
        },
      ],
      fullImageBgSection: {
        title: 'How We Can Help You Succeed With Our Consultations',
        text: `
          Your mission and ideas are world-class. We have the expertise to amplify your success.
          We respect the expertise you have in your niche, which is why we’ll include your opinions
          and feedback every step of the way. We will work collaboratively to get your marketing plan
          where exactly you want it to be.
        `,
      },
    },
  ],
} as const;

export const spec4Content = {
  header: {
    title: 'Search Engine Optimization Services',
    subTitle: 'Setup and maintenance services',
    text: `
      It is a common misconception that search engine optimization is starting to become less important. Sure, sometimes ads
      end up getting promoted much stronger than organic content, but it is still important to rank high on the search engines.
      Placing on the first page often brings success for an online business with minimal effort. \b 
      There are several areas of SEO you have to build a framework for and then maintain. The three key areas of you need to be
      aware of are on-page, technical, and off-site. You’re probably most familiar with on-page strategies, as that is the area
      dealing with your website’s content, keywords, and metadata. Technical SEO deals with optimizing the structure of your site
      so that search engines are able to find and understand your website; while off-site consists of developing relationships
      with other webmasters by linking to their content and writing relevant content that others will want to link to, which helps
      build your inbound links and makes your website more credible to search engines. \b
      That’s just the beginning of it, too. There are so many ever-changing elements to consider, it’s easy to get overwhelmed. That’s
      why we’re here: We know the ins and outs of SEO, how to identify things about your website that could be harming your ranking and
      how to fix it. A successful strategy requires a lot of meticulous hard work, and we’re willing to put in however many hours it takes
      to get you where you need to be.
    `,
  },
  passionSections: [
    {
      title: 'Our Search Engine Optimization services include',
      passions: [
        {
          title: 'Basic Services',
          text: `
            We will analyze your on-page content and keywords and help you identify which keywords work for you and which are too
            competitive that you need to start moving away from. We’ll also provide feedback on how to best incorporate keywords
            that target user intent so that search engines see your page as relevant. If applicable, you will also receive a list
            of meta tags and broken links that need to be updated on the backend of your site so that search engines are able to
            get a more accurate read on your site. Keep in mind that search engine algorithms usually take more time to really
            register these changes, and results may not become impactful for a few months. 
          `,
          cardText: 'Basic SEO',
          buttonText: 'Basic SEO',
          routerConfig: {
            subtype: 'basic maintenance',
          },
        },
        {
          title: 'Maintenance Services',
          text: `
            We will go a few steps further than our basic service by maintaining this SEO work on a month-to-month basis. By keeping
            us around consistently, we’ll be able to oversee and nurture your search engine ranking. We recommend this maintenance plan
            to companies who don’t have an in-house optimization strategist and don’t plan on getting one for some time.
          `,
          cardText: 'Maintenance',
          buttonText: 'Maintenance',
          routerConfig: {
            subtype: 'modernization',
          },
        },
        {
          title: 'Comprehensive Audit Services',
          text: `
            We will complete a full audit of both your business model and application code to determine all areas that could be improved.
            Our team of experts will utilize resources such as web crawlers and audience research tools to provide you a thorough evaluation
            to benchmark your current search engine ranking in comparison to your industry’s standard. From there, we will break down and then
            rebuild your business’s online strategy. We will also work with you to develop KPIs and milestones to help measure your success
            and maximize ROI.
          `,
          cardText: 'Maintenance',
          buttonText: 'Maintenance',
          routerConfig: {
            subtype: 'comprehensive',
          },
        },
      ],
      fullImageBgSection: {
        title: 'SEO is basis of lead generation',
        text: `
          Ensuring maximized SEO is always at the basis of our marketing plans because it’s one of the most important factors in your company’s
          success online. We do recommend leveraging paid advertisements for marketing eventually, but only when we know your page is ready to
          attract clicks. If you’re using common keywords with high search volume, then your ad is going to be bidding against larger, more
          established companies and won’t bring the results you’re hoping for. 
        `,
      },
    },
  ],
} as const;

export const typesLookup: any = {
  'lead generation': {
    name: 'Lead Generation',
    path: '/specialties/lead',
    values: [
      'Pay Per Lead',
      'Lead Capture',
      'Portal',
    ],
  },
  'website design': {
    name: 'Website Design',
    path: '/specialties/development',
    values: [
      'Basic Web',
      'E Commerce',
      'Commercial Grade',
    ],
  },
  'business consultation': {
    name: 'Business Consultation',
    path: '/specialties/consultation',
    values: [
      'App Audit',
      'Business Audit',
      'Comprehensive Audit',
    ],
  },
  'seo services': {
    name: 'SEO Services',
    path: '/specialties/seo',
    values: [
      'Basic Maintenance',
      'Modernization',
      'Comprehensive',
    ],
  },
} as const;

export const specialtyLookup: any = {
  'pay per lead': {
    title: 'Pay Per Lead',
    subtitle: 'Pay only for exactly the leads you need',
    type: 'lead generation',
    specialty: 'pay per lead',
    price: 'Starting at $30 per lead',
  },
  'lead capture': {
    title: 'Personal Lead Capture Pages',
    subtitle: 'Custom web forms personalized to consistently pull in leads for your business.',
    type: 'lead generation',
    specialty: 'lead capture',
    price: 'Starting at 500$',
  },
  portal: {
    title: 'Lead Portal',
    subtitle: 'The Lead Portal is a place to quicklessly and easily que leads based on the amount and specialty you need.',
    type: 'lead generation',
    specialty: 'portal',
    price: 'Starting at 1k/month',
  },
  'basic web': {
    title: 'Basic Website',
    subtitle: `
      Custom designed 3 page site with 3 visual assets and 1 form. 1 Year free hosting.
    `,
    type: 'website design',
    specialty: 'basic web',
    price: 'Starting at $249',
  },
  'e commerce': {
    title: 'Startup Website',
    subtitle: `
      Get a dedicated project manager to deliver a custom 5 page site with 5 visual assets and 3 custom forms. 1 free content update per month.
    `,
    type: 'website design',
    specialty: 'e commerce',
    price: 'Starting at $499',
  },
  'commercial grade': {
    title: 'Professional Website',
    subtitle: `
      The professional package, not only will you have a custom project manager, you will get unlimited revisions, 8 pages, 10 custom
      images, and 3 free content updates per month. This plan includes 2 years of free hosting.
    `,
    type: 'website design',
    specialty: 'commercial grade',
    price: 'Starting at $999',
  },
  'app audit': {
    title: 'Standard Audit',
    subtitle: 'We will look at your existing application and determine the best course of action to improve as needed.',
    type: 'business consultation',
    specialty: 'app audit',
    price: 'Starting at 1k',
  },
  'business audit': {
    title: 'Business Audit',
    subtitle: 'We can help with streamlining tasks and cutting costs from your current business model.',
    type: 'business consultation',
    specialty: 'business audit',
    price: 'Starting at 3k',
  },
  'comprehensive audit': {
    title: 'Comprehensive Audit',
    subtitle: 'We can help with all aspects of your business, from improving the efficiency of your source code, to improving the efficiency of your business model.',
    type: 'business consultation',
    specialty: 'comprehensive audit',
    price: 'starting at 5k',
  },
  'basic maintenance': {
    title: 'Basic SEO Maintenance',
    subtitle: 'Basic SEO service intended on keeping your site on the first page of search engines.',
    type: 'seo services',
    specialty: 'basic maintenance',
    price: 'starting at $500',
  },
  modernization: {
    title: 'SEO Modernization',
    subtitle: 'You already have a solid SEO setup, however you noticed your ranks starting to go down on google, we can help.',
    type: 'seo services',
    specialty: 'modernization',
    price: 'starting at 1k',
  },
  comprehensive: {
    title: 'Comprehensive SEO Services',
    subtitle: 'We will completely audit and update the current SEO as needed, as well as maintain the lastes changes to ensure your business is always on the first page.',
    type: 'seo services',
    specialty: 'comprehensive',
    price: 'Starting at 1k/Month',
  },
};

export const allContent = {
  landing: {},
  about: aboutContent,
  lead: spec1Content,
  aquascapes: spec2Content,
  discus: spec3Content,
  microscapes: spec4Content,
} as const;

export const designPricingValues = [
  {
    title: 'Basic Website',
    price: 249,
    perks: [
      {
        bold: 'Custom Design',
      },
      {
        bold: '3',
        text: 'Page Website',
      },
      {
        bold: '3',
        text: 'Stock Images',
      },
      {
        bold: '2',
        text: 'Revisions',
      },
      {
        bold: '1',
        text: 'Custom Form',
      },
      {
        bold: '1',
        text: 'Year Free Hosting',
      },
    ],
    routerConfig: {
      subtype: 'basic web',
    },
  },
  {
    title: 'Startup Package',
    price: 499,
    perks: [
      {
        bold: 'Custom Design',
      },
      {
        bold: 'Dedicated Project Manager',
      },
      {
        bold: '5',
        text: 'Page Website',
      },
      {
        bold: '5',
        text: 'Stock Images',
      },
      {
        bold: '3',
        text: 'Revisions',
      },
      {
        bold: '3',
        text: 'Custom Form',
      },
      {
        bold: '1',
        text: 'Free Content Update(s) Per Month',
      },
      {
        bold: '1',
        text: 'Year Free Hosting',
      },
    ],
    routerConfig: {
      subtype: 'e commerce',
    },
  },
  {
    title: 'Professional Package',
    price: 999,
    perks: [
      {
        bold: 'Custom Design',
      },
      {
        bold: 'Dedicated Project Manager',
      },
      {
        bold: 'Analytics Setup',
      },
      {
        bold: 'SEO Setup',
      },
      {
        bold: '8',
        text: 'Page Website',
      },
      {
        bold: '10',
        text: 'Stock Images',
      },
      {
        bold: 'Unlimited',
        text: 'Revisions',
      },
      {
        bold: '5',
        text: 'Custom Forms',
      },
      {
        bold: '3',
        text: 'Free Content Update(s) Per Month',
      },
      {
        bold: '2',
        text: 'Years Free Hosting',
      },
    ],
    routerConfig: {
      subtype: 'commercial grade',
    },
  },
];

export const marketingPricingValues = [
  {
    title: 'Search Engine Optimization',
    price: 499,
    perks: [
      {
        bold: 'Dedicated SEO Expert',
      },
      {
        bold: 'First Page of Google',
      },
      {
        bold: '3',
        text: 'Pages of SEO Optimization',
      },
      {
        bold: '1st',
        text: 'Month Free',
      },
    ],
    routerConfig: {
      subtype: 'basic web',
    },
  },
  {
    title: 'Pay Per Click',
    price: 249,
    perks: [
      {
        bold: 'Research Driven Strategy',
      },
      {
        bold: 'Dedicated PPC Expert',
      },
      {
        bold: 'Validated Leads',
      },
      {
        bold: '3',
        text: 'Visual Marketing Assets',
      },
      {
        bold: '1st',
        text: 'Month Free (Excluding Price Per Click)',
      },
    ],
    routerConfig: {
      subtype: 'e commerce',
    },
  },
  {
    title: 'Email Marketing',
    price: 449,
    perks: [
      {
        text: 'Dedicated Email Marketing Specialist',
      },
      {
        bold: '2',
        text: 'Custom Email Templates',
      },
      {
        bold: '2',
        text: 'Custom Email Capture Forms',
      },
      {
        bold: '3',
        text: 'Free Content Updates Per Month',
      },
      {
        bold: '1st',
        text: 'Month Free',
      },
    ],
    routerConfig: {
      subtype: 'commercial grade',
    },
  },
];
