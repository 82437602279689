


























































import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { FullBgCard } from '@/client/components/_theme';

@Component({
  components: {
    FullBgCard,
  },
})
export default class PassionsSection extends Vue {
  @Prop({ required: true })
  readonly passions!: Array<any>;

  @Prop({ required: true })
  readonly images!: any;

  @Prop({ default: false })
  readonly noBg!: boolean;

  currentIndex = 0;
  isMobile = window.innerWidth < 769;

  // eslint-disable-next-line
  getUrl(passion: any) {
    if (passion.url) {
      return passion.url;
    }

    return undefined;
  }

  updateIndex = () => {
    const index = this.currentIndex;
    const newIndex = index + 1;

    this.currentIndex = newIndex;

    return newIndex;
  }
}
