

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VideoHeader extends Vue {
  @Prop({ required: true })
  readonly textItems!: any;
}
